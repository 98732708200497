import React, { Component } from "react"
import { Helmet } from "react-helmet"

import HeroImage from '../components/1.3_total_cv_events/HeroImage'
import Main from '../components/1.3_total_cv_events/Main'
import Header from "../components/header/Header"
import ISI from '../components/isi/ISI'
import Footer from '../components/footer/Footer'
import Modal from '../components/modal/Modal'
import HomeModal from '../components/modal/HomeModal'
import SEO from "../components/seo"

import Loader from "../components/loader"


export default class TEPrevention extends Component {

  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
      isMobile: false,
      modal:  null,
      href: null
    }
  }

  componentDidMount() {
    let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;
    this.setState({ isMobile: bool })
  }

  goModal = (modal, href) => {
    this.setState(() => ({
     modal: modal,
     href: href
   }))
  }

  modalClosed = () => {
    this.setState(() => ({ modal: null }))
  }


  render() {

    return (
      <>
      <Loader>
      <SEO
        title="CV Outcomes-Total Events Analysis"
        keywords={[""]}
        description="VASCEPA reduced first, subsequent, and total CV events. Learn more about first and subsequent primary composite endpoint events."/>
        <HomeModal/>
        <Modal modal={this.state.modal} href={this.state.href} modalClosed={this.modalClosed}/>
        <Header isMobile={this.state.isMobile} hideNav={this.state.hideNav} goModal={this.goModal}/>
        <div className="grad"></div>
        <HeroImage onClick={this.handleClick} bckgrdImage={'header1-3'} isMobile={this.state.isMobile}/>
        <div className="wrapper">
          <Main isMobile={this.state.isMobile} goModal={this.goModal}/>
        </div>
        <ISI disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product."/>
          <div className="ref-outer">
        <div className="footnotes-container">
          <div className="footnotes">
            <p className="cv-footnote">
            CV=cardiovascular; CVD=cardiovascular disease; FDA=Food and Drug Administration; <em>JACC=Journal of the American College of Cardiology</em>; MI=myocardial infarction; TG=triglyceride.
          </p>
            <p className="cv-footnote hang">
          *In the REDUCE-IT study, over 5 years VASCEPA reduced 159 events (comprising Nonfatal MI, Nonfatal Stroke, CV Death, Coronary Revascularization, or Unstable Angina Requiring Hospitalization) for every 1000 patients versus placebo.
          </p>
          </div>
        </div>
        <div className="footnotes-container">
          <div className='references'>
              <p className="cv-footnote"><strong>References: 1. </strong>Bhatt DL, Steg PG, Miller M, et al; on behalf of the REDUCE-IT Investigators. Effects of icosapent ethyl on total ischemic events: from REDUCE-IT. <em>J Am Coll Cardiol.</em> 2019;73(22):2791-2802. 
              <strong> 2. </strong>Bhatt DL, Steg PG, Miller M, et al. Reduction in total ischemic events in the reduction of cardiovascular events with icosapent ethyl–intervention trial. Presented at: American College of Cardiology 68th Annual Scientific Session & Expo; March 16-18, 2019; New Orleans, LA.
              <strong> 3. </strong>Mach F, Baigent C, Catapano AL, et al. 2019 ESC/EAS Guidelines for the management of dyslipidaemias: lipid modification to reduce cardiovascular risk: The Task Force for the management of dyslipidaemias of the European Society of Cardiology (ESC) and European Atherosclerosis Society (EAS). <em>Eur Heart J.</em> 2020;41(1):111-188. 
              <strong> 4. </strong>American Diabetes Association. 10. Cardiovascular Disease and Risk Management: <em>Standards of Medical Care in Diabetes—2021. Diabetes Care.</em> 2021;44(suppl 1):S125-S150.
              <strong> 5. </strong>Arnold SV, Bhatt DL, Barsness GW, et al. Clinical Management of Stable Coronary Artery Disease in Patients With Type 2 Diabetes Mellitus: A Scientific Statement From the American Heart Association. <em>Circulation</em>. 2020;141(19):e779-e806.
              <strong> 6. </strong>Orringer CE, Jacobson TA, Maki KC. National Lipid Association Scientific Statement on the use of icosapent ethyl in statin-treated patients with elevated triglycerides and high or very-high ASCVD risk. <em>J Clin Lipidol.</em> 2019;13(6):860-872. 
              <strong> 7. </strong>Garber AJ, Handelsman Y, Grunberger G, et al. Consensus Statement by the American Association of Clinical Endocrinologists and American College of Endocrinology on the Comprehensive Type 2 Diabetes Management Algorithm – <em>2020 Executive Summary. Endocr Pract.</em> 2020:26(1):107-139.
              <strong> 8. </strong>Virani SS, Morris PB, Agarwala A, et al. 2021 ACC expert consensus decision pathway on the management of ASCVD risk reduction in patients with persistent hypertriglyceridemia: a report of the American College of Cardiology Solution Set Oversight Committee. <em>J Am Coll Cardiol</em>. 2021;78(9):960-993.</p>
          </div>
        </div>
          </div>
        <Footer />
        </Loader>
      </>
    )
  }
}
