import React from "react"
import Windowshade from "../windowshade/Windowshade"
import Magnify_icon from "../../images/magnify-icon.svg"
import Collapsible from '../collapsible/Collapsible'
import CentralEventsGraphDesk1 from '../../images/graphs/desktop/centraltotalevents_chart/Central-total-events-1@2x.png'
import CentralEventsGraphDesk2 from '../../images/graphs/desktop/centraltotalevents_chart/Central-total-events-2@2x.png'
import CentralEventsGraphDesk3 from '../../images/graphs/desktop/centraltotalevents_chart/Central-total-events-3@2x.png'
import CentralEventsGraphDesk4 from '../../images/graphs/desktop/centraltotalevents_chart/Central-total-events-4@2x.png'
import CentralEventsGraphDesk5 from '../../images/graphs/desktop/centraltotalevents_chart/Central-total-events-5@2x.png'
import CentralEventsGraphDesk6 from '../../images/graphs/desktop/centraltotalevents_chart/Central-total-events-6@2x.png'

import reduction_in_CV_event from '../../images/graphs/reduction_in_CV_event.png'


import CentralEventsGraphMobile from '../../images/graphs/mobile/centraltotalevents_chart-mobile.png'
import CentralEventsGraphShade from '../../images/graphs/mobile/centraltotalevents_chart-shade.png'
import './styles.css'


export default (props) => {

  function goModal(evt) {

    const dataAtt = evt.target.dataset.modal;
    const dataHref = evt.target.dataset.href || null;
    //console.log(dataAtt,dataHref )
    props.goModal(dataAtt, dataHref);
  };
  
  
  

  return (
    <main className="center-grid mobile-m-b-20">
      {/* <a href="http://www.onlinejacc.org/content/73/22/2791" target="_blank" rel="noopener noreferrer" className="homeBtn">
          Download the publication
        </a> */}
      <section className="endpoint-outcome-sec-one cr-container">
        <div className="windowshade-container">
          <div className="windowshade-header">
            <p className="">See Graph</p>
          </div>
          <Windowshade>
            <div className="shade-copy-container">
              <p className="shade-body-copy">30% RRR IN TOTAL EVENTS</p>
              <p className="shade-body-copy-alt">
                RR=0.70 (95% CI, 0.62-0.78); <em>P</em>=0.00000000036
              </p>
            </div>

            <div className="windowshade-chartheadline-container">
              <h3 className="windowshade-chartheadline">
                Reduction in first, subsequent, and total CV events with VASCEPA
              </h3>
              <img
                className="magnify_icon"
                data-modal="totalevents_chart_zoom"
                src={Magnify_icon}
                onClick={goModal}
                alt=""
              />
            </div>

            <img
              className="chart mobile-w-90"
              src={CentralEventsGraphShade}
              alt=""
            />
            <div className="windowshade-footer">
              <p className="">close</p>
            </div>
          </Windowshade>
        </div>

        <div className="chart-container-centering">
          <div className="chart-container">
            <h4 className="cv-chartheadline">
              Reduction in first, subsequent, and total CV events with VASCEPA
            </h4>
            <div
              className="chartlockup-events animation-container"
              style={{ width: "580px" }}
            >
              <img
                className="events-chart"
                src={
                  props.isMobile
                    ? CentralEventsGraphMobile
                    : reduction_in_CV_event
                }
                alt=""
              />
              {props.isMobile ? null : (
                <img
                  className="magnify_icon"
                  data-modal="totalevents_chart_zoom"
                  src={Magnify_icon}
                  onClick={goModal}
                  alt=""
                />
              )}
            </div>
            {/* animated chart */}
            
            <p className="cv-chart-footnote">
              Adapted from the{" "}
              <em>Journal of the American College of Cardiology</em>, Vol 73/22,
              Bhatt DL, Steg PG, Miller M, et al. Effects of icosapent ethyl on
              total ischemic events, pp 2791-2802. Copyright 2019, with
              permission from Elsevier.
            </p>
            <p className="cv-chart-footnote">
              CI=confidence interval; RR=rate ratio; RRR=relative risk
              reduction.
            </p>
          </div>
          <div className="chart-sidebar">
            <ul className="content-list">
              <li className="primary-list-item">
                VASCEPA demonstrated a 30% risk reduction in total CV events
              </li>
              <li className="primary-list-item">
                Primary composite endpoint was defined as a composite of
                Nonfatal MI, Nonfatal Stroke, CV Death, Coronary
                Revascularization, or Unstable Angina Requiring Hospitalization
              </li>
            </ul>
          </div>
        </div>

        <div className="events-callout">
          <div className="cta-container m-center fit-content">
            <h3 className="cta-copy">
              {" "}
              See the data in the <em>JACC</em> publication
            </h3>
            <button
              data-modal="leavingsite-modal"
              data-href="http://www.onlinejacc.org/content/early/2019/03/01/j.jacc.2019.02.032"
              onClick={goModal}
              className="cta-btn"
            >
              View now
            </button>
          </div>
          <article className="grad-border">
            <p className="callout-copy">
              Even after the first event, there is still substantial reduction in subsequent events with VASCEPA
            </p>
          </article>
          <div className="cv-events-copy-container">
            <p className="body-copy">
              In the REDUCE-IT<sup>®</sup> study, over 5 years VASCEPA reduced
              159 events (comprising Nonfatal MI, Nonfatal Stroke, CV Death,
              Coronary Revascularization, or Unstable Angina Requiring
              Hospitalization) for every 1000 patients.
            </p>
            <p className="body-copy">
              Recurrent event exploratory analysis reflects a series of
              prespecified statistical models, one of which was{" "}
              <em>post hoc</em>. Data not opined on by FDA.
            </p>
            <p className="body-copy">
              Please see the full publication in the{" "}
              <em>Journal of the American College of Cardiology</em>, as noted
              above.
            </p>
          </div>

          <div className="cta-container m-center fit-content">
            <h3 className="cta-copy">
              IPE is recognized by multiple organizations
              <sup className="callout-sup">3-8</sup>
            </h3>
            <a href="/guidelines/" className="cta-btn">
              See more
            </a>
          </div>
        </div>

        {/* <a href="https://vascepahcp.com/wp-content/themes/vascepatwentyseventeen/assets/pdf/VAS-02199_REDUCE-IT_Total%20Ischemic_Events_PDF_Dec_19.pdf" target="_blank" rel="noopener noreferrer" className="homeBtn">CV event insert pdf </a> */}
      </section>
    </main>
  )
  }
