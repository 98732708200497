import Hero from "../hero/Hero"
import React from "react"

const SubheadMobile = props => {
  return (
    <div>
      <p className="cv-eyebrow dk-blue">
        <span className="bold700">
          In a <em>prespecified</em> and exploratory analysis
        </span>{" "}
        of adults on maximally tolerated statins with TG ≥150 mg/dL and
        established CVD or diabetes and ≥2 CVD risk factors
      </p>

      <h1 className="headline">
        <span>
          When added to a statin, significantly
          <span class="bold700"> reduce the risk of the next CV event</span> with VASCEPA<sup>1,2</sup>
        </span>
      </h1>
    </div>
  )
}

const SubheadDesk = props => {
  return (
    <div>
      <p className="herosub dk-blue">
        <span className="bold700">
          In a <em>prespecified</em> and exploratory analysis
        </span>{" "}
        of adults on maximally tolerated statins with TG ≥150 mg/dL and
        established CVD
        <br /> or diabetes and ≥2 CVD risk factors
      </p>
      <h1 className="headline">
        <span>
          Help substantially reduce{" "}
          <span className="bold700">
            first, subsequent, and total
            <br />
          </span>{" "}
          CV events
        </span>
      </h1>
    </div>
  )
}

const Subhead = isMobile => (isMobile ? <SubheadMobile /> : <SubheadDesk />)

export default props => (
  <Hero bckgrdImage={props.bckgrdImage}>
    <Subhead isMobile={props.isMobile} />
  </Hero>
)
